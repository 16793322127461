import React from "react";

export class SubComponent<P = any, S = any> extends React.Component<P, S> {

    public componentDidMount(): void {
        this.subscribe(this.props);
    }

    public componentWillUnmount(): void {
        this.unsubscribe(this.props);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any): void {
        if (this.shouldResubscribe(this.props, prevProps)) {
            this.unsubscribe(prevProps);
            this.subscribe(this.props);
        }
    }

    protected subscribe(props: P): void {
        // Chill
    }

    protected unsubscribe(props: P): void {
        // Chill
    }

    protected shouldResubscribe(newProps: P, oldProps: P): boolean {
        return false;
    }
}
