// Use this to simplify class names generation.
// Example: classNames({"a": true, "b": false, "c": 1 === 1})
// Returns "a c"
export const classNames: (setup: { [className: string]: boolean }) => string =
    (setup: { [className: string]: boolean }) => {
        const classes: string[] = [];

        for (const setupKey in setup) {
            if (setup[setupKey])
                classes.push(setupKey);
        }

        return classes.join(" ");
    };
