import React, {ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {HostService} from "../../../services/host/HostService";
import {ActionType} from "@interactio/plugin-sdk";
import {JsonValue} from "@interactio/ts-sdk";
import {Checkbox, FieldMessage, Grid, GridItem, IconName, Input} from "@interactio/component-library";

interface Props {
    name: string;
    hostService: HostService;
    action: ActionType<unknown, unknown>;
}

interface State {
    returnData: string;
    isReturnDataValid: boolean;
    actionThrows: boolean;
}

export class HostAction extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        const actionData = this.props.hostService.getActionData(props.name);

        this.state = {
            returnData: JSON.stringify(actionData[0]),
            actionThrows: actionData[1],
            isReturnDataValid: true
        };
    }

    private onReturnDataValueChanged(value: string): void {
        this.setState({returnData: value}, this.updateActionData.bind(this));
    }

    private onActionThrowsValueChange(checked: boolean): void {
        this.setState({actionThrows: checked}, this.updateActionData.bind(this));
    }

    private updateActionData(): void {
        const {actionThrows, returnData} = this.state;

        try {
            const returnDataJsonValue = JSON.parse(returnData) as JsonValue;

            this.props.hostService.setActionData(this.props.name, returnDataJsonValue, actionThrows);
            this.setState({isReturnDataValid: true});
        } catch {
            this.setState({isReturnDataValid: false});
        }
    }

    protected subscribe(props: Props): void {
        const actionData = this.props.hostService.getActionData(props.name);

        this.setState({
            actionThrows: actionData[1],
            returnData: JSON.stringify(actionData[0]),
            isReturnDataValid: true
        });
    }

    protected shouldResubscribe(newProps: Props, oldProps: Props): boolean {
        return newProps.name !== oldProps.name;
    }

    public render(): ReactNode {
        const {name, action} = this.props;
        const {returnData, actionThrows, isReturnDataValid} = this.state;

        const fieldMessage = isReturnDataValid ? action.description : "Invalid JSON data";

        return (
            <Grid spacing={2}>
                <GridItem>
                    <Input value={returnData}
                           onChange={this.onReturnDataValueChanged.bind(this)}
                           name={name}
                           label={"Response value"}
                           error={!isReturnDataValid}/>

                    {action.description &&
                        <div style={{marginTop: "4px"}}>
                            <FieldMessage error={!isReturnDataValid}
                                          iconName={isReturnDataValid ?
                                              IconName.Info : IconName.Warning}
                                          message={fieldMessage}/>
                        </div>}
                </GridItem>

                <GridItem>
                    <Checkbox
                        active={actionThrows}
                        name={`${name}-throws`}
                        label={"Throws"}
                        onChange={this.onActionThrowsValueChange.bind(this)}/>
                </GridItem>
            </Grid>
        );
    }
}
