import {HostService} from "../../../services/host/HostService";
import React, {ReactNode} from "react";
import {Modal} from "../modal/Modal";
import {SubComponent} from "../../../utilities/SubComponent";
import {Plugin} from "@interactio/plugin-sdk";
import {Debug} from "@interactio/ts-sdk";

interface Props {
    hostService: HostService;
    name: string;
}

type ModalTuple = [Promise<Plugin>, ((element: HTMLDivElement) => void) | undefined];

interface State {
    modals: Map<number, ModalTuple>;
    modalHeights: Map<number, number>;
}

export class PluginModalHook extends SubComponent<Props, State> {
    private modalCount: number = 0;

    public constructor(props: Props) {
        super(props);

        this.state = {
            modals: new Map<number, ModalTuple>(),
            modalHeights: new Map<number, number>()
        };
    }

    private async placementProvider(pluginPromise: Promise<Plugin>): Promise<HTMLDivElement> {
        const pluginIndex = this.modalCount++;

        const instancePromise = new Promise<HTMLDivElement>(((resolve) => {
            this.state.modals.set(pluginIndex, [pluginPromise, resolve]);
        }));

        pluginPromise
            .then((plugin: Plugin) => {
                plugin
                    .addObserver("will-terminate", this, () => {
                        this.closeModal(pluginIndex);
                    })
                    .addObserver("content-height-changed", this, (newHeight: number) => {
                        this.state.modalHeights.set(pluginIndex, newHeight);
                        this.setState({modalHeights: this.state.modalHeights});
                    });
            })
            .catch((error: Error) => {
                Debug.warn("Error mounting modal plugin", error);
            });

        this.setState({modals: this.state.modals});

        return instancePromise;
    }

    private resolveRef(ref: HTMLDivElement | null, modalIndex: number): void {
        const {modals} = this.state;

        const resolver = modals.get(modalIndex)?.[1];

        if (resolver && ref) {
            resolver(ref);
        }
    }

    private closeModal(modalIndex: number): void {
        this.state.modals.delete(modalIndex);
        this.state.modalHeights.delete(modalIndex);

        this.setState({modals: this.state.modals, modalHeights: this.state.modalHeights});
    }

    protected subscribe(props: Props): void {
        this.props.hostService.hostUtility?.registerHook(this.props.name, this.placementProvider.bind(this));

        props.hostService.addObserver("onPluginWillUnmount", this, this.onPluginUnmounted);
    }

    protected unsubscribe(props: Props): void {
        props.hostService.removeObserver(this);
    }

    private onPluginUnmounted(pluginId: number): void {
        const {modals} = this.state;

        modals.delete(pluginId);
        this.setState({modals});
    }

    private unmountPlugin(modalIndex: number): void {
        const pluginPromise = this.state.modals.get(modalIndex)?.[0];

        if (!pluginPromise)
            return;

        pluginPromise
            .then((plugin: Plugin) => {
                this.props.hostService.hostUtility?.unmount(plugin);
            })
            .catch((error: Error) => {
                Debug.warn("Error unmounting plugin", error);
            });
    }

    public render(): ReactNode {
        const {modals, modalHeights} = this.state;

        return (
            <div>
                {[...modals.entries()].map((value) => {
                    const modalIndex = value[0];
                    const height = modalHeights.get(modalIndex);

                    return (
                        <Modal key={modalIndex}
                               height={height}
                               pluginPromise={value[1][0]}
                               refCallback={(ref) => {
                                   this.resolveRef(ref, modalIndex);
                               }}
                               onClose={() => {
                                   this.unmountPlugin(modalIndex);
                               }}
                        />
                    );
                })}
            </div>
        );
    }
}
