import React, {CSSProperties, ReactNode} from "react";
import styles from "./Modal.module.scss";
import {classNames} from "../../../utilities/UI";
import {Grid, GridItem, GridJustifyContent, Icon, IconName} from "@interactio/component-library";
import {SubComponent} from "../../../utilities/SubComponent";
import {Plugin} from "@interactio/plugin-sdk";

interface Props {
    refCallback: (ref: HTMLDivElement | null) => void;
    onClose: () => void;
    pluginPromise: Promise<Plugin>;
    height?: number;
}

interface State {
    x: number;
    y: number;
    offsetX: number;
    offsetY: number;
    dragged: boolean;
}

export class Modal extends SubComponent<Props, State> {
    private onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => void;
    private onMouseUp: (e: MouseEvent) => void;
    private onMouseMove: (e: MouseEvent) => void;
    private headerRef?: HTMLElement | null;

    public constructor(props: Props) {
        super(props);

        this.state = {
            x: 350,
            y: 300,
            dragged: false,
            offsetX: 0,
            offsetY: 0
        };

        this.onMouseDown = this.onMouseDownEvent.bind(this);
        this.onMouseUp = this.onMouseUpEvent.bind(this);
        this.onMouseMove = this.onMouseMoveEvent.bind(this);
    }

    protected subscribe(props: Props): void {
        document.addEventListener("mouseup", this.onMouseUp);
    }

    protected unsubscribe(props: Props): void {
        document.removeEventListener("mousemove", this.onMouseMove);
        document.removeEventListener("mouseup", this.onMouseUp);
    }

    private onMouseDownEvent(e: React.MouseEvent<HTMLDivElement>): void {
        // Only left mouse button
        if (e.button !== 0 || !this.headerRef) return;

        const target = e.target as HTMLDivElement;

        // Do not start drag on close icon click
        if (target.classList.contains(styles.closeIcon))
            return;

        const offsetX = e.pageX - this.headerRef.getBoundingClientRect().left;
        const offsetY = e.pageY - this.headerRef.getBoundingClientRect().top;

        this.setState({
            dragged: true,
            offsetX,
            offsetY
        }, () => {
            document.addEventListener("mousemove", this.onMouseMove);
        });

        e.stopPropagation();
        e.preventDefault();
    }

    private onMouseUpEvent(e: MouseEvent): void {
        e.stopPropagation();
        e.preventDefault();

        document.removeEventListener("mousemove", this.onMouseMove);
        this.setState({dragged: false});
    }

    private onMouseMoveEvent(e: MouseEvent): void {
        if (this.state.dragged) {
            this.setState({
                x: e.pageX - this.state.offsetX,
                y: e.pageY - this.state.offsetY
            });
        }

        e.stopPropagation();
        e.preventDefault();
    }

    public render(): ReactNode {
        const {height} = this.props;
        const {x, y, dragged} = this.state;

        const modalClasses = classNames({
            [styles.modal]: true,
            [styles.dragged]: dragged
        });

        const modalStyle: CSSProperties = {left: x, top: y, height: height ? "auto" : "50vh"};

        return (
            <div
                className={modalClasses}
                style={modalStyle}>

                <div className={styles.header}
                     ref={(ref) => {
                         this.headerRef = ref;
                     }}
                     onMouseDown={this.onMouseDown}>
                    <Grid justifyContent={GridJustifyContent.SpaceBetween}>
                        <GridItem xs={"auto"}>
                            <Icon name={IconName.GripDotsVertical}/>
                        </GridItem>

                        <GridItem xs={"auto"}>
                            <div className={styles.closeIcon}
                                 onClick={this.props.onClose}>
                                <Icon name={IconName.Minus}/>
                            </div>
                        </GridItem>
                    </Grid>
                </div>


                <div style={{height}} className={styles.content} ref={this.props.refCallback}/>

                {/* Overlay is shown to prevent mouse entering iframe while dragging the modal.
                Entering iframe zone will prevent mouse move event from firing on this window. */}
                {dragged && <div className={styles.overlay}/>}
            </div>
        );
    }
}
