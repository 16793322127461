import React, {ReactNode} from "react";
import {MultiContext} from "../../utilities/MultiContext";
import {hostContext} from "../../context/HostContext";
import {HostControls} from "../host-controls/HostControls";
import {PluginControls} from "../plugin-controls/PluginControls";
import {SubComponent} from "../../utilities/SubComponent";
import {SandboxLayout} from "../plugin-layout/SandboxLayout";
import {HostService} from "../../services/host/HostService";
import {Grid, GridAlignItems, GridItem} from "@interactio/component-library";
import {NotificationsContainer} from "../host-controls/notifications-container/NotificationsContainer";
import {ContextMenuWrapper} from "./ContextMenuWrapper";

export class Sandbox extends SubComponent {
    public render(): ReactNode {
        return (
            <MultiContext.Consumer contexts={{hostContext}}>
                {(context: { hostContext: HostService }) => (
                    <>
                        <Grid spacing={0} aroundSpacing={0} alignItems={GridAlignItems.Stretch}>
                            <GridItem xs={3}>
                                <HostControls hostService={context.hostContext}/>
                            </GridItem>

                            <GridItem xs={6}>
                                <SandboxLayout hostService={context.hostContext}/>
                            </GridItem>

                            <GridItem xs={3}>
                                <PluginControls hostService={context.hostContext}/>
                            </GridItem>
                        </Grid>

                        <NotificationsContainer hostService={context.hostContext}/>

                        <ContextMenuWrapper hostService={context.hostContext}/>
                    </>
                )}
            </MultiContext.Consumer>
        );
    }
}
