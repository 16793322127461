import React, {ReactElement, ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {HostService} from "../../../services/host/HostService";
import {Notification, NotificationType, NotificationVariant} from "@interactio/plugin-sdk";
import {Timeout} from "@interactio/ts-sdk";
import {Grid, GridItem, ToastManager, ToastOptions, ToastType} from "@interactio/component-library";
import {NotificationItem} from "./NotificationItem";
import styles from "./NotificationsContainer.module.scss";
import {PromptNotification} from "./PromptNotification";

type NotificationTuple = [Notification, Timeout];

interface Props {
    hostService: HostService;
}

interface State {
    notifications: Map<number, NotificationTuple>;
    promptNotification?: Notification;
}

export class NotificationsContainer extends SubComponent<Props, State> {
    private notificationCounter: number = 0;

    public constructor(props: Props) {
        super(props);

        this.state = {
            notifications: new Map<number, NotificationTuple>()
        };
    }

    protected subscribe(props: Props): void {
        props.hostService.addObserver("onManifestUpdated", this, this.startObservingNotifications);
        props.hostService.hostUtility?.addObserver("onNotificationPosted", this, this.onNotificationPosted);
    }

    private startObservingNotifications(): void {
        this.props.hostService.hostUtility?.removeObserver(this);
        this.props.hostService.hostUtility?.addObserver("onNotificationPosted", this, this.onNotificationPosted);
    }

    protected unsubscribe(props: Props): void {
        props.hostService.hostUtility?.removeObserver(this);
    }

    private static addToast(notification: Notification): void {
        const toastOptions: ToastOptions = {
            message: notification.message
        };

        switch (notification.type) {
            case NotificationType.Info:
                toastOptions.type = ToastType.Info;
                break;
            case NotificationType.Success:
                toastOptions.type = ToastType.Success;
                break;
            case NotificationType.Warning:
                toastOptions.type = ToastType.Warning;
                break;
            case NotificationType.Error:
                toastOptions.type = ToastType.Error;
                break;
                // no default
        }

        ToastManager.add(toastOptions);
    }

    private onNotificationPosted(notification: Notification): void {
        const notificationId = this.notificationCounter++;

        if (notification.variant === NotificationVariant.Prompt) {
            this.setState({promptNotification: notification});
        } else if (notification.variant === NotificationVariant.Toast) {
            NotificationsContainer.addToast(notification);
        } else if (notification.variant === NotificationVariant.Default) {
            const timeout: Timeout = setTimeout(() => {
                this.clearNotification(notificationId);
            }, notification.timeout);

            this.state.notifications.set(notificationId, [notification, timeout]);

            this.forceUpdate();
        }
    }

    private clearNotification(notificationId: number): void {
        this.state.notifications.delete(notificationId);

        this.forceUpdate();
    }

    private closePromptNotification(): void {
        this.setState({promptNotification: undefined});
    }

    public render(): ReactNode {
        const {notifications, promptNotification} = this.state;
        const notificationItems: ReactElement[] = [];

        notifications.forEach(([notification], id: number) => {
            notificationItems.push(
                <GridItem key={`notification-${id}`}>
                    <NotificationItem notification={notification} onClose={() => this.clearNotification(id)}/>
                </GridItem>
            );
        });

        return (
            <>
                <div className={styles.container}>
                    {notificationItems.length > 0 &&
                        <Grid spacing={2} aroundSpacing={2}>
                            {notificationItems}
                        </Grid>
                    }
                </div>

                {promptNotification &&
                    <PromptNotification notification={promptNotification}
                                        onClose={() => this.closePromptNotification()}
                    />
                }
            </>
        );
    }
}
