import React from "react";
import {HostService} from "../../services/host/HostService";
import {HostUtility} from "@interactio/plugin-sdk";
import {ContextMenu} from "@interactio/plugin-host-utilities";
import {SubComponent} from "../../utilities/SubComponent";

interface Props {
    hostService: HostService;
}

interface State {
    hostUtility?: HostUtility;
}

export class ContextMenuWrapper extends SubComponent<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {};
    }

    protected subscribe(props: Props): void {
        props.hostService.addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    protected unsubscribe(props: Props): void {
        props.hostService.removeObserver(this);
    }

    private onManifestUpdated(): void {
        this.setState({hostUtility: this.props.hostService.hostUtility});
    }

    public render(): React.ReactNode {
        const {hostUtility} = this.state;

        return (
            <>
                {hostUtility &&
                    <ContextMenu hostUtility={hostUtility}/>
                }
            </>
        );
    }
}
