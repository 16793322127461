import React, {ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {Manifest} from "@interactio/plugin-sdk";
import {Debug, JsonValue} from "@interactio/ts-sdk";
import {
    Button,
    FieldMessage,
    Grid,
    GridItem,
    Header,
    IconName,
    Input,
    Option,
    Select
} from "@interactio/component-library";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: Manifest;
    selectedEvent: string;
    eventData: string;
    isEventDataValid: boolean;
}

export class HostEvents extends SubComponent<Props, State> {

    public constructor(props: Props) {
        super(props);

        const {manifest} = props.hostService;

        this.state = {
            manifest,
            selectedEvent: "",
            isEventDataValid: true,
            eventData: "null"
        };
    }

    protected subscribe(props: Props): void {
        this.props.hostService
            .addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    private onManifestUpdated(manifest?: Manifest): void {
        const defaultEvent = Object.keys(manifest?.events || {})[0];

        this.setState({manifest, selectedEvent: defaultEvent});
    }

    protected unsubscribe(props: Props): void {
        this.props.hostService.removeObserver(this);
    }

    private onSelectedEventChange(value: string | number): void {
        this.setState({selectedEvent: String(value)});
    }

    private onEventDataChange(eventData: string): void {
        let isEventDataValid;

        try {
            JSON.parse(eventData);

            isEventDataValid = true;
        } catch {
            isEventDataValid = false;
        }

        this.setState({eventData, isEventDataValid});
    }

    private triggerEvent(): void {
        const {selectedEvent, eventData} = this.state;

        let data: JsonValue;

        try {
            data = JSON.parse(eventData || "null") as JsonValue;
        } catch (e) {
            Debug.warn("Unable to parse data as a JSON.", e);
            return;
        }

        this.props.hostService.triggerHostEvent(selectedEvent, data);
    }

    public render(): ReactNode {
        const {manifest, selectedEvent, eventData, isEventDataValid} = this.state;

        return (
            <div>
                <Header title={"Events"}>Events</Header>

                {manifest?.events &&
                    <Grid spacing={2}>
                        <GridItem>
                            <Select
                                value={selectedEvent}
                                label={"Event name"}
                                name={"event-id"}
                                onChange={this.onSelectedEventChange.bind(this)}>

                                {Object.keys(manifest.events).map((event) =>
                                    (<Option key={event}
                                             value={event}>
                                        {event}
                                    </Option>))}
                            </Select>
                        </GridItem>

                        <GridItem>
                            <Input
                                name={"event-data"}
                                value={eventData}
                                label={"Event data"}
                                onChange={this.onEventDataChange.bind(this)}
                                placeholder={"Event data"}
                                error={!isEventDataValid}/>

                            {!isEventDataValid &&
                                <div style={{marginTop: "4px"}}>
                                    <FieldMessage error={!isEventDataValid}
                                                  message={"Invalid JSON data"}
                                                  iconName={IconName.Warning}/>
                                </div>}
                        </GridItem>

                        <GridItem>
                            <Button
                                onClick={this.triggerEvent.bind(this)}
                                disabled={!eventData || !selectedEvent || !isEventDataValid}
                                leftIconName={IconName.SendMessage}>
                                Send
                            </Button>
                        </GridItem>
                    </Grid>
                }

                {!manifest?.events && <FieldMessage message={"Host does not have any events"}/>}
            </div>
        );
    }
}
