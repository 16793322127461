import React, {ChangeEvent, ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {Manifest} from "@interactio/plugin-sdk";
import {Debug} from "@interactio/ts-sdk";
import {Button, Grid, GridItem, Header, Modal, Size, TextVariant, Typography} from "@interactio/component-library";
import defaultManifest from "../../../defaults/default_sandbox_manifest.json";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: string;
}

const MANIFEST_LOCAL_STORAGE_KEY = "sandbox_manifest";

export class HostConfigDialog extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            manifest: HostConfigDialog.getManifestInitialState()
        };
    }

    private static getManifestInitialState(): string {
        return localStorage.getItem(MANIFEST_LOCAL_STORAGE_KEY) || JSON.stringify(defaultManifest, null, 4);
    }

    private loadHostConfiguration(): void {
        const {manifest} = this.state;

        try {
            if (manifest !== undefined) {
                const manifestJSON = JSON.parse(manifest) as Manifest;

                this.props.hostService.createHost(manifestJSON);

                localStorage.setItem(MANIFEST_LOCAL_STORAGE_KEY, manifest);
            } else {
                Debug.error("Error loading host configuration, manifest is not defined");
            }
        } catch (e) {
            Debug.error("Error loading host configuration manifest", e);
        }
    }

    private onManifestTextAreaValueChange(e: ChangeEvent<HTMLTextAreaElement>): void {
        this.setState({manifest: e.target.value});
    }

    private loadDefaultSandboxManifest(): void {
        this.setState({manifest: JSON.stringify(defaultManifest, null, 4)});
    }

    public render(): ReactNode {
        const {manifest} = this.state;

        return (
            <Modal show={true} size={Size.Large}>
                <Grid spacing={2}>
                    <GridItem xs={12}>
                        <Header title={"Sandbox configuration"} description={"Enter your host manifest JSON config"}/>
                        <Typography variant={TextVariant.Link}>
                            Or <span
                            onClick={this.loadDefaultSandboxManifest.bind(this)}>use default configuration</span>
                        </Typography>
                    </GridItem>

                    <GridItem xs={12}>
                        <textarea
                            style={{width: "100%", minHeight: 600}}
                            value={manifest}
                            onChange={this.onManifestTextAreaValueChange.bind(this)}/>
                    </GridItem>

                    <GridItem xs={12}>
                        <Button onClick={this.loadHostConfiguration.bind(this)}>
                            Load
                        </Button>
                    </GridItem>
                </Grid>
            </Modal>
        );
    }
}
