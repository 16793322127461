import React, {ReactNode} from "react";
import {HostService} from "../../services/host/HostService";
import {SubComponent} from "../../utilities/SubComponent";
import {Manifest} from "@interactio/plugin-sdk";
import {HostConfigDialog} from "./host-config-dialog/HostConfigDialog";
import {HostActions} from "./host-actions/HostActions";
import {HostEvents} from "./host-events/HostEvents";
import {LoadPluginDialog} from "./load-plugin-dialog/LoadPluginDialog";
import {
    Button,
    Grid,
    GridAlignItems,
    GridDirection,
    GridItem,
    Header,
    IconName
} from "@interactio/component-library";
import {ActionContainers} from "./action-containers/ActionContainers";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: Manifest;
    isLoadPluginDialogVisible: boolean;
}

export class HostControls extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            manifest: props.hostService.manifest,
            isLoadPluginDialogVisible: false
        };
    }

    protected subscribe(): void {
        this.props.hostService
            .addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    private onManifestUpdated(manifest?: Manifest): void {
        this.setState({manifest});
    }

    protected unsubscribe(): void {
        this.props.hostService.removeObserver(this);
    }

    private onLoadPluginButtonClick(): void {
        this.setState({isLoadPluginDialogVisible: !this.state.isLoadPluginDialogVisible});
    }

    private onLoadPluginDialogClose(): void {
        this.setState({isLoadPluginDialogVisible: false});
    }

    public render(): ReactNode {
        const {hostService} = this.props;
        const {manifest, isLoadPluginDialogVisible} = this.state;

        return (
            <>
                {!manifest && <HostConfigDialog hostService={this.props.hostService}/>}

                <div className={"sidebarWrapper"}>
                    <Grid aroundSpacing={2} spacing={0} direction={GridDirection.Column}>
                        <GridItem xs>
                            <Grid aroundSpacing={0}
                                  spacing={4}
                                  direction={GridDirection.Column}
                                  alignItems={GridAlignItems.Stretch}>
                                <GridItem xs={"auto"}>
                                    <Header title={"Sandbox controls"}/>
                                </GridItem>

                                <GridItem xs={"auto"}>
                                    <Button onClick={this.onLoadPluginButtonClick.bind(this)}
                                            leftIconName={IconName.Plus}>
                                        Add plugin
                                    </Button>
                                </GridItem>

                                <GridItem xs={"auto"}>
                                    <HostActions hostService={hostService}/>
                                </GridItem>

                                <GridItem xs={"auto"}>
                                    <HostEvents hostService={hostService}/>
                                </GridItem>

                                <GridItem xs={"auto"}>
                                    <ActionContainers hostService={hostService}/>
                                </GridItem>
                            </Grid>
                        </GridItem>
                    </Grid>
                </div>

                {
                    manifest &&
                    isLoadPluginDialogVisible &&
                    <LoadPluginDialog
                        hostService={hostService}
                        manifest={manifest}
                        onDialogClose={this.onLoadPluginDialogClose.bind(this)}
                    />
                }
            </>
        );
    }
}
