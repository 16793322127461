import {
    HostUtility,
    Manifest,
    Placement,
    Plugin, PluginConfig, PluginMessageValueOrVoid,
    ScopeConfig
} from "@interactio/plugin-sdk";
import {Debug, EventEmitterBase, JsonValue} from "@interactio/ts-sdk";

interface HostServiceEvents {
    onManifestUpdated: (manifest?: Manifest) => void;
    onPluginWillUnmount: (pluginId: number) => void;
    onPluginMounted: (pluginId: number) => void;
}

export interface HookConfiguration {
    name: string;
    placement: Placement;
}

type PluginEventListener = (data: PluginMessageValueOrVoid) => void;

// This tuple is used to save action return data and boolean indicating whether this action should throw
type ActionData = [JsonValue, boolean];

export class HostService extends EventEmitterBase<HostServiceEvents> {
    private _hostUtility?: HostUtility;
    private hookResolvers: Map<string, (instanceId: number) => Promise<HTMLElement>>;
    private _manifest?: Manifest;

    // Monitored events list by plugin
    private _monitoredEvents: Map<Plugin, Map<string, PluginEventListener>>;

    private actionDataMap: Map<string, ActionData>;

    public constructor() {
        super();

        this.hookResolvers = new Map<string, () => Promise<HTMLElement>>();
        this.actionDataMap = new Map<string, ActionData>();

        this._monitoredEvents = new Map<Plugin, Map<string, PluginEventListener>>();
    }

    public get manifest(): Manifest | undefined {
        return this._manifest;
    }

    public get hostUtility(): HostUtility | undefined {
        return this._hostUtility;
    }

    public getMonitoredEvents(plugin: Plugin): Map<string, PluginEventListener> {
        return this._monitoredEvents.get(plugin) || new Map<string, PluginEventListener>();
    }

    public addMonitoredEvent(plugin: Plugin, eventName: string): void {
        const map = this._monitoredEvents.get(plugin) || new Map<string, PluginEventListener>();


        const pluginEventListener = (data: PluginMessageValueOrVoid) => {
            HostService.onPluginEvent(plugin, eventName, data);
        };

        plugin.addEventListener(eventName, pluginEventListener);
        map.set(eventName, pluginEventListener);

        Debug.log(`Added event listener for ${eventName}`);

        this._monitoredEvents.set(plugin, map);
    }

    private static onPluginEvent(plugin: Plugin, eventName: string, data: PluginMessageValueOrVoid): void {
        Debug.log(`Plugin ${plugin.manifest.title} (${plugin.id}) event "${eventName}" fired with data: `, data);
    }

    public removeMonitoredEvent(plugin: Plugin, eventName: string): void {
        const map = this._monitoredEvents.get(plugin);

        if (!map)
            return;

        const event = map.get(eventName);

        if (!event)
            return;

        map.delete(eventName);
        plugin.removeEventListener(eventName, event);

        Debug.log(`Removed event listener for ${plugin.manifest.title} (${plugin.id}) event ${eventName}`);

        if (map.size === 0)
            this._monitoredEvents.delete(plugin);
    }

    public createHost(manifest: Manifest): void {
        this._manifest = manifest;

        const actionDataMapJson = JSON.parse(
            localStorage.getItem(`action-data-map-${this._manifest.name}`) || "{}"
        ) as Record<string, ActionData>;

        this.actionDataMap = new Map(Object.entries(actionDataMapJson));

        const scopes = manifest.permissionScopes.map((scopeConfig: ScopeConfig) => {
            return scopeConfig.scope;
        });

        const pluginsConfig: PluginConfig[] = [];

        this._hostUtility = new HostUtility(pluginsConfig, manifest, {
            target: Placement.Fullscreen,
            properties: {},
            scopes
        });

        this._hostUtility.start();

        this._hostUtility
            .addObserver("plugin-did-mount", this, this.pluginDidMount)
            .addObserver("plugin-will-unmount", this, this.pluginWillUnmount);

        // Intercepting requests
        const proxy = new Proxy(this, {get: this.requestHandlerInterceptor.bind(this)});

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._hostUtility.setRequestHandler(proxy);

        this.dispatchEvent("onManifestUpdated", this._manifest);
    }

    private requestHandlerInterceptor(_target: this,
                                      property: string): unknown {

        return (source: Plugin, data: JsonValue | void) => {
            return this.handleRequest(property, source, data);
        };
    }

    private handleRequest(name: string, source: Plugin, data: JsonValue | void): Promise<JsonValue | void> {
        const actionResult = this.getActionData(name);

        if (actionResult) {
            Debug.log(`Plugin ${source.manifest.title} (${source.id}) sent a request "${name}" with data: `, data);

            if (!actionResult[1]) {
                // Does not throw
                return Promise.resolve(actionResult[0]);
            } else {
                return Promise.reject(new Error(JSON.stringify(actionResult[0])));
            }
        } else {
            return Promise.reject(Error("Unsupported request"));
        }
    }

    public get plugins(): readonly Plugin[] {
        return this._hostUtility?.plugins || [];
    }

    private pluginWillUnmount(plugin: Plugin): void {
        Debug.log(`Plugin ${plugin.manifest.title} (${plugin.id}) unmounted`);

        this._monitoredEvents.delete(plugin);

        this.dispatchEvent("onPluginWillUnmount", plugin.id);
    }

    private pluginDidMount(plugin: Plugin): void {
        Debug.log(`Plugin ${plugin.manifest.title} (${plugin.id}) mounted`);

        this.dispatchEvent("onPluginMounted", plugin.id);
    }

    public getActionData(actionName: string): ActionData {
        return this.actionDataMap.get(actionName) || [null, false];
    }

    public setActionData(actionName: string, returnValue: JsonValue, canThrow: boolean): void {
        this.actionDataMap.set(actionName, [returnValue, canThrow]);

        if (this.manifest) {
            const actionDataMapJson = JSON.stringify(Object.fromEntries(this.actionDataMap.entries()));
            localStorage.setItem(`action-data-map-${this.manifest.name}`, actionDataMapJson);
        }
    }

    public unmountPlugin(pluginId: number): void {
        if (!this._hostUtility)
            throw new Error("Error unmounting plugin, host is not ready");

        const plugin = this._hostUtility.plugins.find((p: Plugin) => {
            return p.id === pluginId;
        });

        if (!plugin)
            throw new Error("Error unmounting plugin, plugin not found");

        this._hostUtility.unmount(plugin);
    }

    public triggerHostEvent(eventName: string, eventData: JsonValue): void {
        if (!this._hostUtility)
            return;

        this._hostUtility?.postEvent(eventName, eventData);

        Debug.log(`Host event "${eventName}" triggered with data: `, eventData);
    }
}
