import React, {ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {ActionType, Manifest} from "@interactio/plugin-sdk";
import {HostAction} from "../host-action/HostAction";
import {FieldMessage, Grid, GridItem, Header, Option, Select} from "@interactio/component-library";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: Manifest;
    selectedAction: string;
}

export class HostActions extends SubComponent<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            manifest: props.hostService.manifest,
            selectedAction: ""
        };
    }

    protected subscribe(): void {
        this.props.hostService
            .addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    private onManifestUpdated(manifest?: Manifest): void {
        const defaultAction: string = manifest?.actions ? Object.keys(manifest.actions)[0] : "";

        this.setState({manifest, selectedAction: defaultAction});
    }

    protected unsubscribe(): void {
        this.props.hostService.removeObserver(this);
    }

    private onSelectedActionChange(value: string | number): void {
        this.setState({selectedAction: String(value)});
    }

    public render(): ReactNode {
        const {manifest, selectedAction} = this.state;

        let actionNames: string[] = [];

        if (manifest)
            actionNames = Object.keys(manifest.actions);

        const hasActions = manifest && actionNames.length > 0;

        return (
            <>
                <Header title={"Actions"}/>

                {!hasActions && <FieldMessage message={"Host does not have any actions"}/>}

                {hasActions &&
                    <Grid spacing={2}>
                        <GridItem>
                            <Select value={selectedAction}
                                    name={"host-action"}
                                    label={"Host action"}
                                    disabled={actionNames.length === 0}
                                    onChange={this.onSelectedActionChange.bind(this)}>

                                {actionNames.map((action) =>
                                    (<Option value={action}
                                             key={`host-action-${action}`}>
                                        {action}
                                    </Option>))}
                            </Select>
                        </GridItem>

                        {!!selectedAction &&
                            <GridItem>
                                <HostAction
                                    action={manifest.actions[selectedAction] as ActionType<unknown, unknown>}
                                    name={selectedAction}
                                    hostService={this.props.hostService}
                                />
                            </GridItem>
                        }
                    </Grid>
                }
            </>
        );
    }
}
