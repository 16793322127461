import React, {Component, ReactNode} from "react";
import {Sandbox} from "./components/sandbox/Sandbox";

export class App extends Component {
    public render(): ReactNode {
        return (
            <Sandbox/>
        );
    }
}
