import React, {ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {ActionItem, ActionItemStatus} from "@interactio/plugin-sdk";
import {GridItem, Loader, TextVariant, Typography} from "@interactio/component-library";
import {Debug} from "@interactio/ts-sdk";
import styles from "./ActionItem.module.scss";
import {classNames} from "../../../utilities/UI";

interface Props {
    actionItem: ActionItem;
}

export class ActionItemComponent extends SubComponent<Props> {
    protected shouldResubscribe(newProps: Props, oldProps: Props): boolean {
        return newProps.actionItem !== oldProps.actionItem;
    }

    protected subscribe(): void {
        this.props.actionItem
            .addObserver("onUpdated", this, this.onActionItemUpdated);
    }

    private onActionItemUpdated(): void {
        this.forceUpdate();
    }

    protected unsubscribe(): void {
        this.props.actionItem.removeObserver(this);
    }

    private triggerAction(): void {
        this.props.actionItem
            .trigger()
            .catch((error: Error) => {
                Debug.warn("Error triggering action: ", error);
            });
    }

    public render(): ReactNode {
        const {actionItem} = this.props;

        const classes = classNames({
            [styles.actionItem]: true,
            [styles.disabled]: actionItem.status === ActionItemStatus.Disabled,
            [styles.active]: actionItem.status === ActionItemStatus.Active,
        });

        return (
            <>
                {actionItem.status !== ActionItemStatus.Hidden &&
                    <GridItem>
                        <div className={classes} onClick={() => this.triggerAction()}>

                            {actionItem.iconUrl && <img src={actionItem.iconUrl} alt={actionItem.displayName}/>}

                            <Typography variant={TextVariant.Text}>{actionItem.displayName}</Typography>

                            {actionItem.badge &&
                                <div className={styles.badge}>
                                    <Typography variant={TextVariant.Caption}>{actionItem.badge}</Typography>
                                </div>}

                            {actionItem.status === ActionItemStatus.Loading &&
                                <div className={styles.loader}><Loader size={16}/></div>
                            }
                        </div>
                    </GridItem>
                }
            </>
        );
    }
}
