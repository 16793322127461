import React, {ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {ActionContainer, ActionItem} from "@interactio/plugin-sdk";
import {Container, FieldMessage, Grid, GridItem, Header, Size} from "@interactio/component-library";
import {ActionItemComponent} from "./ActionItem";

interface Props {
    hostService: HostService;
    actionContainerName: string;
}

interface State {
    actionContainer?: ActionContainer;
    actionItems: readonly ActionItem[];
}

export class ActionContainerComponent extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            actionItems: []
        };
    }

    protected subscribe(): void {
        this.props.hostService
            .addObserver("onManifestUpdated", this, this.updateActionContainer);
    }

    private updateActionContainer(): void {
        const actionContainer = this.props.hostService?.hostUtility?.getActionContainer(this.props.actionContainerName);

        this.setState({actionContainer, actionItems: actionContainer?.actions || []});

        actionContainer?.addObserver("onActionListUpdated", this, this.onActionListUpdated);
    }

    private onActionListUpdated(): void {
        this.setState({actionItems: this.state.actionContainer?.actions || []});
    }

    protected unsubscribe(): void {
        this.props.hostService.removeObserver(this);
    }

    public render(): ReactNode {
        const {actionItems} = this.state;
        const {actionContainerName} = this.props;

        const hasActionItems = actionItems.length > 0;

        return (
            <>
                <Container border={true} borderRadius={true}>
                    <Grid spacing={1} aroundSpacing={2}>
                        <GridItem>
                            <Header size={Size.Small} title={`Action container "${actionContainerName}"`}/>

                            {!hasActionItems && <FieldMessage
                                message={`Action container "${actionContainerName}" does not have any actions`}/>}
                        </GridItem>

                        {hasActionItems &&
                            actionItems.map((actionItem: ActionItem) => {
                                return (
                                    <ActionItemComponent
                                        actionItem={actionItem}
                                        key={`action-item-${actionItem.hash}`}
                                    />
                                );
                            })
                        }
                    </Grid>
                </Container>
            </>
        );
    }
}
