import React, {ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {Plugin} from "@interactio/plugin-sdk";
import {Debug, JsonValue} from "@interactio/ts-sdk";
import {
    Button,
    FieldMessage,
    Grid,
    GridItem,
    Header,
    IconName,
    Input,
    Option,
    Select,
    Size
} from "@interactio/component-library";

interface Props {
    plugin: Plugin;
    hostService: HostService;
}

interface State {
    selectedAction: string;
    actionData: string;
    isActionDataValid: boolean;
}

export class PluginActions extends SubComponent<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            selectedAction: "",
            actionData: "null",
            isActionDataValid: true
        };
    }

    private onSelectedActionChange(value: string | number): void {
        this.setState({selectedAction: String(value)});
    }

    private onActionDataChange(value: string | number): void {
        const actionData = String(value);
        let isActionDataValid;

        try {
            JSON.parse(actionData);

            isActionDataValid = true;
        } catch {
            isActionDataValid = false;
        }

        this.setState({actionData, isActionDataValid});
    }

    private triggerEvent(): void {
        const {plugin} = this.props;
        const {selectedAction, actionData} = this.state;

        let data: JsonValue;

        try {
            data = JSON.parse(actionData || "null") as JsonValue;
        } catch (e) {
            Debug.warn("Unable to parse data as a JSON.", e);
            return;
        }

        plugin.sendRequest(selectedAction, data)
            .then((response) => {
                Debug.log(
                    `${plugin.manifest.title} (${plugin.id}) action ${selectedAction} responded with data: `, response
                );
            })
            .catch((e) => {
                Debug.warn("Error sending request for plugin", e);
            });
    }

    public render(): ReactNode {
        const {plugin} = this.props;
        const {selectedAction, actionData, isActionDataValid} = this.state;

        const manifest = plugin.manifest;
        const actionNames = Object.keys(manifest.actions);

        return (
            <>
                <Header title={"Actions"}/>

                {manifest.actions &&
                    <Grid spacing={2}>

                        <GridItem>
                            <Select value={selectedAction}
                                    name={"plugin-action"}
                                    label={"Action"}
                                    disabled={actionNames.length === 0}
                                    onChange={this.onSelectedActionChange.bind(this)}>

                                {actionNames.map((action) =>
                                    (<Option value={action}
                                             key={`plugin-action-${action}`}
                                             disabled={!plugin.hasActionAccess(action)}>
                                        {action}
                                    </Option>))}
                            </Select>

                            {manifest.actions[selectedAction] &&
                                <div style={{marginTop: "4px"}}>
                                    <FieldMessage message={manifest.actions[selectedAction].description}
                                                  iconName={IconName.Info}/>
                                </div>
                            }
                        </GridItem>


                        <GridItem>
                            <Input
                                label={"Action data"}
                                name={"plugin-action-data"}
                                value={actionData}
                                onChange={this.onActionDataChange.bind(this)}
                                placeholder={"{\"test\": 123}"}/>

                            {
                                !isActionDataValid &&
                                <div style={{marginTop: "4px"}}>
                                    <FieldMessage
                                        iconName={IconName.Warning}
                                        message={"Invalid JSON data"}
                                        error={true}/>
                                </div>
                            }
                        </GridItem>

                        <GridItem>
                            <Button onClick={this.triggerEvent.bind(this)}
                                    disabled={!actionData || !selectedAction || !isActionDataValid}
                                    leftIconName={IconName.SendMessage}>
                                Send
                            </Button>
                        </GridItem>
                    </Grid>
                }

                {!manifest?.actions && <Header title={"Plugin does not have any actions"} size={Size.Small}/>}
            </>
        );
    }
}
