import React, {ReactElement, ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {Notification, NotificationAction, NotificationType} from "@interactio/plugin-sdk";
import {
    Button,
    ButtonColor,
    ButtonVariant, Grid, GridItem,
    Modal,
    TextVariant,
    Typography
} from "@interactio/component-library";

interface Props {
    notification: Notification;
    onClose: () => void;
}

interface State {
    loading: boolean;
}

export class PromptNotification extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    private triggerAction(action: NotificationAction): void {
        this.setState({loading: true}, () => {
            action
                .trigger()
                .finally(() => {
                    this.props.onClose();
                });
        });
    }

    private getActionButtonColor(action: NotificationAction): ButtonColor {
        if (action.secondary)
            return ButtonColor.Gray;

        switch (this.props.notification.type) {
            case NotificationType.Info:
                return ButtonColor.Blue;
            case NotificationType.Success:
                return ButtonColor.Green;
            case NotificationType.Warning:
                return ButtonColor.Black;
            case NotificationType.Error:
                return ButtonColor.Red;
            default:
                return ButtonColor.Blue;
        }
    }

    public render(): ReactNode {
        const {notification, onClose} = this.props;

        const actionButtons: ReactElement[] = notification.actions?.map(
            (action: NotificationAction) => {
                const color: ButtonColor = this.getActionButtonColor(action);

                return (
                    <GridItem key={`prompt-action-${action.name || "void"}`}>
                        <Button
                            color={color}
                            fullWidth
                            variant={ButtonVariant.Solid}
                            onClick={() => this.triggerAction(action)}>
                            {action.title}
                        </Button>
                    </GridItem>
                );
            }) || [];

        return (
            <>
                <Modal closeButton
                       ignoreWrapperClick
                       onClose={onClose}
                       title={notification.title}
                       show={true}>

                    <Grid spacing={2}>
                        <GridItem>
                            <Typography variant={TextVariant.P1}>{notification.message}</Typography>
                        </GridItem>

                        {actionButtons}
                    </Grid>
                </Modal>
            </>
        );
    }
}
