import React, {ReactNode} from "react";
import {SubComponent} from "../../../utilities/SubComponent";
import {HostService} from "../../../services/host/HostService";
import {Manifest, ManifestStorage, Placement, ScopeConfig} from "@interactio/plugin-sdk";
import {Debug} from "@interactio/ts-sdk";
import {
    Button,
    Checkbox,
    Grid,
    GridItem,
    Header,
    IconName,
    Input,
    Modal,
    Option,
    Select,
    Size
} from "@interactio/component-library";

interface Props {
    hostService: HostService;
    manifest: Manifest;
    onDialogClose: () => void;
}

interface State {
    pluginManifestUrl: string;
    pluginManifest?: Manifest;
    permissionScopes: Set<string>;
    selectedPlacement: string;
}

export class LoadPluginDialog extends SubComponent<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            pluginManifestUrl: localStorage.getItem("plugin_manifest_url") || "",
            permissionScopes: new Set<string>(),
            selectedPlacement: "",
        };
    }

    private onPluginManifestUrlChange(value: string): void {
        this.setState({pluginManifestUrl: value});
    }

    private onPluginPermissionChange(scope: string, checked: boolean): void {
        if (checked) {
            this.state.permissionScopes.add(scope);
        } else {
            this.state.permissionScopes.delete(scope);
        }

        this.setState({permissionScopes: this.state.permissionScopes});
    }

    private async fetchPluginManifest(): Promise<void> {
        const {pluginManifestUrl} = this.state;

        const manifest = await ManifestStorage.Instance.resolveManifest(pluginManifestUrl);

        const defaultPlacement = manifest.placements[0];

        this.setState({pluginManifest: manifest, selectedPlacement: defaultPlacement});

        localStorage.setItem("plugin_manifest_url", pluginManifestUrl);
    }

    private onSelectedPlacementChange(value: string | number): void {
        this.setState({selectedPlacement: String(value)});
    }

    private onMountButtonClick(): void {
        const {hostService} = this.props;
        const {pluginManifestUrl, selectedPlacement, permissionScopes} = this.state;

        hostService.hostUtility?.mount(
            pluginManifestUrl, {
                properties: {},
                target: selectedPlacement as Placement,
                scopes: Array.from(permissionScopes)
            })
            .then(() => {
                this.props.onDialogClose();
            })
            .catch((e) => {
                Debug.warn("Error mounting plugin", e);
            });
    }

    public render(): ReactNode {
        const {onDialogClose} = this.props;
        const {
            pluginManifestUrl,
            pluginManifest,
            permissionScopes,
            selectedPlacement
        } = this.state;

        return (
            <Modal show={true} closeButton={true} onClose={onDialogClose.bind(this)} size={Size.Small}>
                {!pluginManifest &&
                    <Grid spacing={2}>
                        <GridItem>
                            <Input
                                key={"manifest-url"}
                                label={"Plugin Manifest URL"}
                                name={"plugin-manifest-url"}
                                value={pluginManifestUrl}
                                onChange={this.onPluginManifestUrlChange.bind(this)}
                                placeholder={"https://plugin.url/manifest.json"}
                            />
                        </GridItem>

                        <GridItem>
                            <Button
                                key={"manifest-url"}
                                onClick={this.fetchPluginManifest.bind(this)}
                                leftIconName={IconName.Download}>
                                Load
                            </Button>
                        </GridItem>
                    </Grid>
                }

                {pluginManifest &&
                    <Grid spacing={2}>
                        <GridItem>
                            {/* Scopes */}
                            <Header size={Size.Small} title={"Scopes"}/>

                            {
                                pluginManifest.permissionScopes.map((scopeConfig: ScopeConfig) =>
                                    (<div key={`action-${scopeConfig.scope}`}>
                                        <Checkbox
                                            label={`${scopeConfig.title} (${scopeConfig.description})`}
                                            active={permissionScopes.has(scopeConfig.scope)}
                                            name={`action-${scopeConfig.scope}`}
                                            onChange={
                                                (checked) =>
                                                    this.onPluginPermissionChange(scopeConfig.scope, checked)
                                            }/>
                                        <br/>
                                    </div>))
                            }

                        </GridItem>

                        <GridItem>
                            {/* Hook */}
                            {pluginManifest.placements.length > 0 &&
                                <Select
                                    label={"Placement"}
                                    value={selectedPlacement}
                                    onChange={this.onSelectedPlacementChange.bind(this)}
                                    name={"sandbox-hook"}>
                                    {
                                        pluginManifest.placements.map((placement: Placement) =>
                                            (<Option value={placement} key={`placement-${placement}`}>
                                                {`${placement}`}
                                            </Option>)
                                        )
                                    }
                                </Select>
                            }

                            {pluginManifest.placements.length === 0 &&
                                <Header size={Size.Small}
                                        title={"No hooks are available for this plugin"}/>}
                        </GridItem>
                        <GridItem>
                            <Button
                                onClick={this.onMountButtonClick.bind(this)}
                                disabled={!selectedPlacement}
                                leftIconName={IconName.Plus}>
                                Mount
                            </Button>
                        </GridItem>
                    </Grid>
                }
            </Modal>
        );
    }
}
