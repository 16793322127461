import React, {ReactNode} from "react";
import {HostService} from "../../../services/host/HostService";
import {SubComponent} from "../../../utilities/SubComponent";
import {Manifest} from "@interactio/plugin-sdk";
import {FieldMessage, Grid, GridItem, Header} from "@interactio/component-library";
import {ActionContainerComponent} from "./ActionContainer";

interface Props {
    hostService: HostService;
}

interface State {
    manifest?: Manifest;
}

export class ActionContainers extends SubComponent<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            manifest: props.hostService.manifest
        };
    }

    protected subscribe(props: Props): void {
        this.props.hostService
            .addObserver("onManifestUpdated", this, this.onManifestUpdated);
    }

    private onManifestUpdated(manifest?: Manifest): void {
        this.setState({manifest});
    }

    protected unsubscribe(props: Props): void {
        this.props.hostService.removeObserver(this);
    }

    public render(): ReactNode {
        const {manifest} = this.state;
        const {hostService} = this.props;

        const hasActionContainers = manifest?.actionContainers && manifest.actionContainers.length > 0;

        return (
            <Grid spacing={2}>
                <GridItem>
                    <Header title={"Action containers"}/>

                    {!hasActionContainers && <FieldMessage message={"Host does not have any action containers"}/>}
                </GridItem>

                {hasActionContainers &&
                    manifest.actionContainers.map((actionContainer) => {
                        return (
                            <GridItem key={`action-container-${actionContainer}`}>
                                <ActionContainerComponent
                                    hostService={hostService}
                                    actionContainerName={actionContainer}/>
                            </GridItem>
                        );
                    })
                }
            </Grid>
        );
    }
}
